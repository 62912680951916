<!--
    Le domaine client.oleo100.com redirige maintenant vers la V2 (PWA SENS)
    Les QrCode déjà imprimés dirigent vers "https://client.oleo100.com/index/overviewDevice/44f9b070d5388fd7653282301403e6b47e90ab07"
    Ce composant sert à faire une redirection vers la page public V1 pour s'adapter à ce changement de domaine
-->

<template>
    <div id="redirect-to-qr-code-public-v1"></div>
</template>

<script>
export default {
    name: 'redirectToQrCodePublicV1',
    created() {
        this.$store.dispatch('general/setGlobalLoader', true)
        window.location.href = `https://saas.quatre-factorielle.com/index/overviewDevice/${this.$route.params.uid}`
    },
}
</script>